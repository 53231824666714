import $ from 'jquery';
import React from 'react';
import {appGetUrlPath,appGetMainUrl} from '../function_lib/common_cms';
import './comp_blog_land.css';
let appPathCMS="";
let clsComoBlogLand="";
let ifIframe="";
let ifIframInit=false;
let appPathURL="";
let imgPath="default_articles.jpg"; 
class Comp_Blog_Land extends React.Component{
    
   
    constructor(props){
        super(props);
        appPathCMS=appGetUrlPath();
        appPathURL=appGetMainUrl();
        
    }
    
httpGet(theUrl)
{
    ifIframInit=true;
    //alert(theUrl);
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", appPathURL+"getContentPgData"+"?pg_name="+theUrl, true ); // false for synchronous request
    xmlHttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
            //alert("i m done xml");
            //alert(xmlHttp.responseText);
            ifIframe=xmlHttp.responseText;
            //alert(ifIframe);
           // clsComoBlogLand.onLoadIframe(ifIframe);
           // Typical action to be performed when the document is ready:
           //document.getElementById("blog_landing").innerHTML = xmlHttp.responseText;
        }
    };
    xmlHttp.send();
    // alert(xmlHttp.responseText);
    // return xmlHttp.responseText;
}
    componentWillReceiveProps(props){
        this.props=props;
       

        if(this.props.blogData && this.props.blogData["col5"].indexOf(".html")!==-1){
           //alert(ifIframe);
            if(!ifIframInit){
                
                //alert(this.props.blogData["col5"]);
                ifIframe=this.httpGet(this.props.blogData["col5"]);
            }
            if(ifIframe){
               
            }
           
            
           
        }
        if(this.props.blogData["col4"]!==""){
            imgPath=this.props.blogData["col4"];
        }
        //alert("child props"+JSON.stringify(this.props.blogData));
        //alert(this.props.blogData["col4"]);
        //this.forceUpdateHandler();
        // Adjusting the iframe height onload event
        $("#blog_landing").html(this.props.blogData["col2"]+this.props.blogData["col3"]);
    }
    componentDidMount(){
        clsComoBlogLand=this;

        // $('#myIframe').bind('DOMSubtreeModified', function () {
            
        //     var links = document.getElementById("blog_landing").getElementsByTagName("a");
        //     var len = links.length;
        //     alert("i am in change"+$("#blog_landing").html());
        //   });
       
    }
    componentDidUpdate(){
       
        //document.getElementById("blog_landing").innerHTML='<object type="text/html" data="'+appPathCMS+this.props.blogData["col5"]+'" ></object>';
        var links = document.getElementById("blog_landing").getElementsByTagName("a");
        var len = links.length;
        //alert(len);
        for(var i=0; i<len; i++)
        {
           links[i].target = "_blank";
           //alert("check");
        }
        if(ifIframe!==""){
            document.getElementById("blog_landing").innerHTML=ifIframe;
            this.onLoadIframe();
        }
       
        //alert("component updated");
        // var testingcount= window.frames["myIframe"].getElementsByTagName("a");
        // alert("tt="+testingcount.length);
      
       
    }
    onLoadIframe(srcs){
     // alert("i am in click");
     // document.getElementById("blog_landing").innerHTML=srcs;
     // return;
        //document.getElementById("myIframe").src="#";
        var links = document.getElementById("blog_landing").getElementsByTagName("a");
        
        var len = links.length;
        //alert("loaded"+len);
        for(var i=0; i<len; i++)
        {
           links[i].target = "_blank";
           //alert("check");
        }
    }
    render(){
        return(<div className="blog-posts pt-70px pr-10">
       
        <div id="leading_blog" className="single-blog-post  blog-grid-post">
            <div className="blog-post-media">
                
                {this.props.blogData["col6"].trim()!==""?<div className="blog-image single-blog">
                <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen width="100%"
    src={this.props.blogData["col6"]}>
    </iframe>
                </div>:<div className="blog-image single-blog">
                    <img src={appPathCMS+imgPath} alt="blog" />
                </div>
                }
                 
            </div>
            <div className="pro-details-social-info">
                            <span>Share</span>
                            <div className="social-info">
                                <ul className="d-flex">
                                    <li>
                                        <a href="#"><i className="ion-social-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-google"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="ion-social-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
            <div className="blog-post-content-inner mt-6">
                <div className="blog-athor-date">
                    {/* <a className="blog-date" href="#">14 November</a> */}
                </div>
                <h4 className="blog-title">
                    {/* <a className="display-overflow" href="javascript:void(0)">{this.props.blogData["col1"]}</a> */}
                    
                        <h4 className="black">{this.props.blogData["col1"]}</h4>
                    
                    </h4>
                <p id="blog_landing">
                 
                </p>
            
            </div>
            
        </div>
        
    </div>)
    }
}
export default Comp_Blog_Land;